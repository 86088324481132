import React from "react";

const Popup = () => {
  return (
    <div className="pop">
        <div className="pop_wrap">
            <a className="close" href="javascript:void(0);"><img src="img/btn_close.png" alt="" /></a>
            <div className="pop_01">
                <span>活動說明</span>
                <div>
                    「全上」飆風賽熱血應援投票展開！<br />
                    為你支持的隊伍投票站隊，將有機會獲得最新手機 iPhone15！<br /><br />
                    ■ 投票時間：2023年10月20日~11月3日23:59止<br />
                    ■ 參與資格：本活動限台港澳地區玩家參與<br />
                    ■ 活動辦法：為支持的隊伍投票應援，即可獲得一次抽獎機會，多次投票獲獎機率越大。<br />
                    ■ 投票方式：<br />
                    <ul>
                        <li>點擊「投票」按鈕，並登入個人Facebook帳號、完成驗證後即可投票。</li>
                        <li>每個Facebook帳號每天最多可投一票。</li>
                        <li>快將網頁分享出去，為你支持的隊伍拉票應援吧！</li>
                    </ul>
                    <br />
                    ■ 活動獎勵：<br />
                    iPhone15 (抽1名)<br />
                    AirPods Pro (第 2 代 USBC版) (抽1名)<br />
                    MyCard專屬卡 1000點(抽5名)<br />
                    MyCard專屬卡 500點(抽20名)<br />
                </div>
            </div>
            <div className="pop_02">
                <span>注意事項</span>
                <div>
                    【全上飆風賽】熱血應援投票活動<br />
                    為你支持的隊伍投票站隊，將有機會獲得最新手機 iPhone15！
                    <br /><br />
                    【領獎流程】
                    <ul>
                        <li>本活動最終獲獎名單將於11/15前於官方FB粉絲團公布，請車手們隨時留意相關消息。</li>
                        <li>得獎者屆時務必遵照領獎須知回報並提供所需資料，以利獎勵發放作業進行。</li>
                        <li>若在2023年10月20日時仍未滿18歲者，則需在報名時獲得監護人同意方得以參與本活動。並在領取獎勵時，提供監護人同意書。</li>
                        <li>請注意，本活動獎勵限台港澳地區玩家領取，恕不受理前述地區以外之領獎申請。</li>
                    </ul>
                    <br /><br />
                    【注意事項】
                    <ul>
                        <li>活動單位有權檢視各參與者之活動參與行為及得獎情形是否涉嫌例：人為操作、惡意灌票、蓄意偽造、多開（重）帳號、短時間異常多筆參與行為、透過任何電腦程式參與活動、詐欺、任何違反會員系統服務合約及停權管理規章之情事者，將一概取消活動資格。
                        </li>
                        <li>活動單位將審視領獎人與得獎人的帳號資料是否相符，如資料不完整、不實、不正確或冒用他人資料報名、逾時未配合或未按照領獎流程提供所需資料者，將視同無條件放棄得獎資格及一切相關權益。
                        </li>
                        <li>本活動參加、得獎資格或獎品不得轉讓予任何第三人，即無法進行取消、退換貨、亦不得要求轉換為遊戲點數/幣值、紅利，或更換其他商品或權益，若不配合領獎程序者視同無條件放棄一切資格及相關權益。
                        </li>
                        <li>本活動獎勵限台港澳地區玩家領取，不受理前述地區以外之領獎申請。台灣地區依稅法規定，中獎金額所得總額超過新台幣20,001
                            元以上，依法須繳10%稅額且領取獎項須配合提供身分證正反面影本、勞務報酬單；港澳地區將以即時匯率換算等值獎金後派發且領取獎項須配合提供身分證件、銀行帳戶資料。若得獎人未能依法繳納應繳稅額與提供領獎資料，即視同無條件放棄得獎資格及一切相關權益。
                        </li>
                        <li>參加者於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範，包含對其個人資料以及遊戲內創作之蒐集、處理和利用，如不同意或有違反，應視為無參加資格。</li>
                        <li>本活動各項辦法及規定，以官方活動公告及活動單位說明為準。活動單位擁有本活動隨時及最終保留、變更、修改、或撤回、取消獎項發送等之權利，若因遇不可抗力之因素，本活動將有一部或全部暫停、延後舉辦或取消之權利。如有其他未盡事宜，活動單位有權隨時補充或修正，並以最新公告為主。
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Popup;
