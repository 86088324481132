import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import queryString from "query-string";
import { getFbLoginUrl } from "../helpers/authUtils";
import Spinner from "../components/Spinner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Popup from "../components/Popup";

import {
    getLoggedInUser,
    setLoggedInUser,
    logoutUser,
  } from "../helpers/authUtils";

const HomeScreen = ({ location, history }) => {

  const [loading, setLoading] = useState(false);
  const [voteOg, setVoteOg] = useState(0);
  const [voteYellow, setVoteYellow] = useState(0);
  const [barOg, setBarOg] = useState(50);
  const [barYellow, setBarYellow] = useState(50);

  const [userInfo, setUserInfo] = useState(getLoggedInUser());

  const parsed = queryString.parse(location.search);
  const siteToken = parsed.site_token;
  //const userInfo = getLoggedInUser();

  useEffect(() => {
    const getVotes = async () => {
      setLoading(true);

      axios
        .get(`/api/teamvote`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setVoteOg(res.data.og);
            setVoteYellow(res.data.yellow);
            setBarOg(res.data.barOg);
            setBarYellow(res.data.barYellow);
          }
        })
        .catch((err) => {
          setLoading(false);
          //console.log(err.message);
        });
    };
    getVotes();

    return () => {};
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      axios
        .post(`/api/teamvote/auth/user`, { siteToken })
        .then((res) => {
          if (res.data) {
            //localStorage.setItem("userInfo", JSON.stringify(data));
            setLoggedInUser(JSON.stringify(res.data));
            setUserInfo(getLoggedInUser());
          }
        })
        .catch((err) => {
          setLoading(false);
          //console.log(err.message);
        });
    };
    if (siteToken) {
      verifyToken();
    }
  }, [siteToken]);

  const vote = (team) => {
    if (!userInfo || !userInfo.token) {
      window.alert("請先登入喔!");
      window.location.href = getFbLoginUrl();
      return;
    }

    const teamName = team==='og' ? '娛樂飆車黨' : '專業競速黨';
    let yes = window.confirm(`確認要把神聖的一票投給「${teamName}」嗎? 每人每天可投一票，投票截止時間為2023/11/3 23：59，把握時間支持的隊伍加油應援吧！`);

    if (yes) {   
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      axios
        .post(`/api/teamvote/event/vote`, { team, action: "vote" }, config)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setVoteOg(res.data.og);
            setVoteYellow(res.data.yellow);
            setBarOg(res.data.barOg);
            setBarYellow(res.data.barYellow);

            window.alert(
              "恭喜您完成投票！"
            );
          }
        })
        .catch((err) => {
          setLoading(false);

          window.alert(err.response.data.message);
          //console.log(err);
        });
    }
  };

  return (
    <>
      <Header 
        userInfo={userInfo}
        logout={() => {
          logoutUser();
          setUserInfo(null);
          history.push("/teamvote");
        }}
      />
      <Fragment>
      <div className="wrapper">
        
        <div className="kv" id="index">
            <h1>巔峰極速 全上飆風賽 11/4直播對決 最速高手全都上</h1>
            <p className="wow fadeInUp" data-wow-delay="0.3s">挺誰由你決定 投票讚聲抽麗寶賽車主題旅店住宿券</p>
            <a className="btn_go_vote wow fadeIn" data-wow-delay="0.3s" href="#vote"><img src="img/btn_down.png" alt="立即應援" /></a>
            <img src="img/team_a.png" alt="" className="team_a wow fadeInLeft" />
            <img src="img/team_b.png" alt="" className="team_b wow fadeInRight" />
        </div>
        <img src="img/bar_01.png" alt="" className="bar_01" />
        
        <div id="vote" className="vote_wrap">
            <div className="event_word">
                <h2>熱血應援你站在哪一對</h2>
                <p>落敗隊伍全上正式賽將懲罰加時3秒，誰將贏在賽道起跑點，由人氣投票決定!</p>
            </div>
            <a href="javascrip:void(0);" className="btn_pop_01"><img src="img/btn_pop.png" alt="活動說明" /></a>
            <img src="img/red_car.png" alt="" className="og_car wow fadeInLeft" data-wow-duration="0.5s" />
            <img src="img/yellow_car.png" alt="" className="yellow_car wow fadeInRight" data-wow-duration="0.5s" />
            <div className="vote_no wow fadeInUp">
                <a href="javascript:void(0);" className="vote_og_no" onClick={() => vote("og")}><span>{voteOg}</span></a>
                <span>票數累積</span>
                <a href="javascript:void(0);" className="vote_yellow_no" onClick={() => vote("yellow")}><span>{voteYellow}</span></a>
            </div>
            <div className="vote_bar wow fadeIn">
                <div className="bar_og" style={{width: `${barOg}%`}}></div>
                <div className="bar_yellow" style={{width: `${barYellow}%`}}></div>
            </div>
        </div>
        <img src="img/bar_top.png" alt="" className="bar_top" />
        
        <div className="info_wrap" id="info">
            <div className="bg_video">
                <video loop="loop" autoplay="autoplay" muted="muted" playsinline="">
                    <source src="img/video.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="info_word wow fadeIn">
                <h2>眾星雲集 飆速全開</h2>
                <span>什麼恩怨情仇 都用速度一決勝負吧</span>
                <p>
                    是「娛樂飆車黨」氣勢逼人，還是「專業競速檔」技壓群雄
                    號召各路最速高手聚集巔峰賽道
                    最強競速玩家展開「全上」對決！
                    一起鎖定 巔峰極速「全上」飆風賽
                    華麗的飆速對決即將展開......
                </p>
            </div>
        </div>
        <img src="img/bar_bottom.png" alt="" className="bar_bottom" />
        <img src="img/red_line.png" alt="" className="red_line" />
        
        <div className="gift_wrap" id="gift">
            <div className="gift_word">
                <h2>活動獎勵</h2>
                <p>
                    投票應援即有機會獲得以下豐厚獎勵
                    麗寶賽車主題旅店住宿券 (抽1名) AirPods Pro2 (抽1名) MyCard專屬卡 1000點 (抽10名) MyC
                </p>
            </div>
            <a href="javascript:void(0);" className="btn_pop_02">
                <img src="img/btn_pop_02.png" alt="注意事項" />
            </a>
            <img className="gift_img" src="img/gift.png" alt="" />
        </div>
        
        <div id="live" className="live_wrap">
            <div className="live_word">
                <h2>巔峰極速 全上飆風賽</h2>
                <p>
                    獲得較多應援票數的隊伍，將獲得贏在起跑點的機會，對手將判加時3秒。
                    直播中將有豐厚獎勵等著車手們領取，抓緊方向盤，準時鎖定直播。
                </p>
            </div>
            <div className="live_cont">
                <div className="live_tab">
                    
                    <a href="javascript:void(0);" className="tab_01">放火</a>
                    
                    <a href="javascript:void(0);" className="tab_02">小葵</a>
                    
                    <a href="javascript:void(0);" className="tab_03">依渟</a>
                    
                    <a href="javascript:void(0);" className="tab_00 active">官方直播</a>
                    
                    <a href="javascript:void(0);" className="tab_04">姚元浩</a>
                    
                    <a href="javascript:void(0);" className="tab_05">艾莉絲</a>
                    
                    <a href="javascript:void(0);" className="tab_06">小草</a>
                </div>
                <div className="live_frame">
                    <div className="video-container">
                        <iframe className="video_box" width="560" height="315"
                            src="https://www.youtube.com/embed/-O1Oon-1dRM?si=KxRo02mR2D0ssQAG"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
      </div>
      </Fragment>
      <Footer />
      <Popup />
    </>
  );
};

export default HomeScreen;
