import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_cont">
          <a href="https://support.longeplay.com.tw/service_quick?param_game_id=G112" target="_blank"></a>
          <img src="img/footer.png" alt="" />
      </div>
    </div>
  );
};

export default Footer;
