import React, { Fragment } from "react";
import { getFbLoginUrl } from "../helpers/authUtils";

const Header = ({ userInfo, logout }) => {

  const shareIt = () => {
    window.FB.ui(
      {
        display: "popup",
        method: "share",
        href: 'https://event.racingmaster.tw/teamvote/',
        redirect_uri: 'https://event.racingmaster.tw/teamvote/',
      },
      (response) => {
        //console.log("share response", response);
      }
    );
  };

  return (
    <Fragment>
      <div className="top">
        <div className="btn_menu">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <a className="logo" href="https://www.racingmaster.game/tw/" target="_blank" title="巔峰極速官網"><img src="img/logo.png" alt="巔峰極速" /></a>
        <div className="nav_left">
            <a href="#index" className="nav_01 active">首頁</a>
            <a href="#vote" className="nav_02">投票應援</a>
            <a href="#info" className="nav_03">賽事簡介</a>
            <a href="#gift" className="nav_04">活動獎勵</a>
            <a href="#live" className="nav_05">鎖定直播</a>
        </div>
          {!userInfo ? (
            <a href={getFbLoginUrl()} className="login"><span>登入</span></a>
          ) : (    
            <Fragment>
            
            <a href="javascript:;" className="login" onClick={logout}><span>{userInfo.name} 登出</span></a>            
            </Fragment>     
          )}
        <div className="sns_link">
            <a href="https://www.racingmaster.game/tw/" target="_blank" className="btn_download"><img src="img/download.png" alt="" /></a>
            <a href="https://www.facebook.com/RacingMasterHMT" className="btn_fb"><img src="img/facebook.png" alt="" /></a>
            <a href="javascript:;" className="btn_link" onClick={() => shareIt()}><img src="img/link.png" alt="" /></a>
        </div>
      </div>
      
      <div className="nav_left for_m">
          <a href="#index" className="nav_01 active">首頁</a>
          <a href="#vote" className="nav_02">投票應援</a>
          <a href="#info" className="nav_03">賽事簡介</a>
          <a href="#gift" className="nav_04">活動獎勵</a>
          <a href="#live" className="nav_05">鎖定直播</a>
      </div>
    </Fragment>
  );
};

export default Header;
