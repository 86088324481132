import React, { Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";

function App() {
  return (
    <Router>
      <Fragment>
      
        <Route path="/teamvote" component={HomeScreen} exact />

      </Fragment>
    </Router>
  );
}

export default App;
